
import React from 'react';
import {
    ArrowLeft,
    Phone,
    Video,
    Search,
    MoreVertical,
    Pin,
    UsersRound,
    Share2,
} from 'lucide-react';
import { Popover, Whisper } from 'rsuite';

export default function ChatNavbar({ userName, profil, unreadCount, user }) {
    const isOnline = true
    const lastSeen = "2 min ago"
    return (
        <nav className="bg-white border-b h-[5em] fixed w-full z-40 border-gray-200 p-1 sm:px-4 sm:py-3 shadow-sm">
            <div className="max-w-7xl mx-auto flex items-center justify-between">
                {/* Left Section */}
                <div className="flex items-center sm:space-x-4">
                    <button
                        className="p-2 hover:bg-gray-100 hidden rounded-full transition-colors"
                        aria-label="Go Back"
                    >
                        <ArrowLeft className="w-5 h-5 text-gray-600" />
                    </button>

                    <div className="flex items-center space-x-3">
                        <div className="relative">
                            <img
                                src={`https://rtc.eac-advisor.org/imageUsers/${profil}`}
                                alt={`${userName}'s avatar`}
                                className="w-10 h-10 rounded-full object-cover"
                            />
                            {isOnline && (
                                <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                            )}
                        </div>

                        <div className="flex flex-col">
                            <div className="font-semibold max-sm:text-[12px] text-gray-800">{userName}</div>
                            <span className="text-sm text-gray-500">
                                {isOnline ? 'Online' : `Last seen: ${lastSeen}`}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div className="flex items-center sm:space-x-2">
                    <button
                        className="p-2 hover:bg-gray-100 rounded-full hidden transition-colors"
                        aria-label="Search"
                    >
                        <Search className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        aria-label="Phone Call"
                    >
                        <Phone className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        className="p-2 hover:bg-gray-100  rounded-full transition-colors"
                        aria-label="Video Call"
                    >
                        <Video className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        className="p-2 hover:bg-gray-100 rounded-full hidden transition-colors"
                        aria-label="Pin Chat"
                    >
                        <Pin className="w-5 h-5 text-gray-600" />
                    </button>

                    <Whisper
                        placement='bottomEnd'
                        trigger='click'

                        speaker={
                            <Popover>
                                <div>
                                    {user && user.map((data, index) => (
                                        <div className='flex gap-1 mt-1 items-center'>
                                            <div className='rounded-full w-5 h-5 overflow-hidden'>
                                                <img className='w-full h-full object-cover' src={`https://rtc.eac-advisor.org/imageUsers/${data.profil}`} alt=' ' />
                                            </div>
                                            <div>
                                                {data.nom_complet}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Popover>
                        }
                    >
                        <div className="relative">
                            <button
                                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                                aria-label="Notifications"
                            >
                                <UsersRound className="w-5 h-5 text-gray-600" />
                            </button>
                            {unreadCount > 0 && (
                                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                                    {unreadCount}
                                </span>
                            )}
                        </div>
                    </Whisper>

                    <button
                        className="p-2 hover:bg-gray-100 hidden rounded-full transition-colors"
                        aria-label="Share"
                    >
                        <Share2 className="w-5 h-5 text-gray-600" />
                    </button>

                    <button
                        className="p-2 hover:bg-gray-100 rounded-full hidden transition-colors"
                        aria-label="More Options"
                    >
                        <MoreVertical className="w-5 h-5 text-gray-600" />
                    </button>
                </div>
            </div>
        </nav>
    );
}