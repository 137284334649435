/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, {
  createContext,
  useContext,
  useState,
} from "react";

const UserContext = createContext();

export function ContextPovider({ children }) {


  const [isVisitor, SetIsVisitor] = useState(false);
  const [isUsers, SetIsUsers] = useState(false);
  const [isAdmin, SetIsAdmin] = useState(false);
  const [isAdminSuper, SetIsAdminSuper] = useState(false);

  const [isSincrire, SetIsSincrire] = useState(false);


  const userId = localStorage.getItem('userId');
  const Tokeni = localStorage.getItem('accessToken_Data');

  const tokenUtile = {
    headers: { Authorization: `Bearer ${Tokeni}` },
  };


  return (
    <UserContext.Provider
      value={{
        Tokeni,
        userId,
        tokenUtile,
        isAdmin, SetIsAdmin,
        isAdminSuper, SetIsAdminSuper,
        isVisitor, SetIsVisitor,
        isSincrire, SetIsSincrire,
        isUsers, SetIsUsers
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useThemes = () => {
  return useContext(UserContext);
};
