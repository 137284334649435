import React, { useEffect, useState } from 'react'
import ChatHeader from './ChatHeader'
import Discuition from './Discuition'
import { feathersClient, socket } from '../../feathersClient/feathersClient';

function ComponentDiscusion() {
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const handleChatClose = () => { console.log("Chat closed"); };




    useEffect(() => {
        feathersClient.reAuthenticate()
            .then((authResult) => {
                setCurrentUser(authResult.user);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération de l\'utilisateur connecté :', error);
            });

        // Lorsqu'il y a une mise à jour des utilisateurs connectés
        socket.on('connected_users_updated', (users) => {
            const filteredUsers = users.filter(
                (user) => user.id !== currentUser?.id
            );


            setConnectedUsers(filteredUsers); // Met à jour l'état
        });
        return () => {
            // Nettoie les écouteurs lorsque le composant est démonté
            socket.off('connected_users_updated');
        };
    }, []);

    const removeDuplicateUsers = (users, idToRemove) => {
        const uniqueUsers = new Map();

        users.forEach(user => {
            // Vérifier si l'utilisateur a un ID différent de celui à retirer
            if (user.id !== idToRemove) {
                // Utiliser l'ID de l'utilisateur comme clé pour garantir l'unicité
                if (!uniqueUsers.has(user.id)) {
                    uniqueUsers.set(user.id, user);
                }
            }
        });

        // Convertir le Map en tableau et retourner
        return Array.from(uniqueUsers.values());
    };


    if (currentUser == null) {
        return (
            <div>Recuperation des information d'un utilisateur connected</div>
        )
    }

    const uniqueUsers = removeDuplicateUsers(connectedUsers, currentUser.id);
    return (
        <div className='overflow-hidden'>
            <div>
                <ChatHeader
                    user={uniqueUsers}
                    unreadCount={uniqueUsers.length}
                    userName={currentUser.nom_complet}
                    userStatus="online"
                    profil={currentUser.profil}
                    onClose={handleChatClose}
                />
                <Discuition />
            </div>
        </div>
    )
}

export default ComponentDiscusion