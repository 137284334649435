import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import authentication from '@feathersjs/authentication-client';
const feathersClient = feathers();

const Token_feathersClient = localStorage.getItem("accessToken_Data")

const socket = io('https://rtc.eac-advisor.org', {
    auth: {
        Token_feathersClient,
    },
}
);

feathersClient.configure(socketio(socket));

feathersClient.configure(authentication({
    storage: window.localStorage,
    storageKey: "accessToken_Data"
}));

// Écouter l'événement 'connect' pour vérifier si la connexion est établie
socket.on('connect', async () => {
    console.log('Connexion au serveur établie avec succès')
});

// Écouter les erreurs de connexion
socket.on('connect_error', (error) => {
    console.log('Erreur de connexion :', error);
});



export { feathersClient, socket };
