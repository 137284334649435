/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { feathersClient } from '../../feathersClient/feathersClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format, isToday, isYesterday, subDays, isSameDay } from 'date-fns';

function Discussion() {
    const tokens = localStorage.getItem("accessToken_Data");

    const [message, setMessage] = useState('')
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const messageService = feathersClient.service('message');
    const messagesEndRef = useRef(null);

    const [messages, setMessages] = useState([]);

    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        if (message.trim() !== "") {
            if (tokens) {
                // Ajoutez un message localement avec l'état "pending"
                const newMessage = {
                    id: Date.now(), // Utilisez un ID temporaire
                    content: message,
                    user_id: userId,
                    createdAt: new Date(),
                    status: "pending",
                };
                setMessages((prev) => [...prev, newMessage]);
                setMessage("");

                try {
                    // Authentification et création du message
                    await feathersClient.authenticate({
                        strategy: "jwt",
                        accessToken: tokens,
                    });
                    const createdMessage = await messageService.create({ content: message });

                    // Remplacez le message local par la réponse du serveur
                    setMessages((prev) =>
                        prev.map((msg) =>
                            msg.id === newMessage.id
                                ? { ...createdMessage, status: "success" }
                                : msg
                        )
                    );
                    fetchMessages()
                } catch (error) {
                    // Marquez le message comme une erreur
                    setMessages((prev) =>
                        prev.map((msg) =>
                            msg.id === newMessage.id ? { ...msg, status: "error" } : msg
                        )
                    );
                    console.error("Erreur lors de l'envoi du message:", error.message);
                    toast.error("Erreur lors de l'envoi du message. Veuillez vous connecter pour continuer.");
                }
            } else {
                toast.warning("Le jeton d'authentification est manquant. Veuillez vous connecter pour continuer.");
                navigate("/");
                localStorage.removeItem("status");
            }
        } else {
            toast.warning("Le champ de message ne doit pas être vide.");
        }
    };






    const fetchMessages = async () => {
        try {
            const initialMessages = await messageService.find();
            const repose = await Promise.all(
                initialMessages.map(async (data) => {
                    const userResponse = await axios.get(`https://rtc.eac-advisor.org/users/${data.user_id}`);
                    const userData = userResponse.data;
                    return { ...userData, ...data };
                })
            );
            setMessages(repose);
        } catch (error) {
            console.error("Erreur lors de la récupération des messages :", error);
        }
    };

    useEffect(() => {
        const authenticateUser = async () => {
            if (tokens) {
                try {
                    const auth = await feathersClient.authenticate({
                        strategy: 'jwt',
                        accessToken: tokens,
                    });
                    setUserId(auth.user.id);
                } catch (error) {
                    console.error("Erreur d'authentification:", error);
                }
            }
        };
        authenticateUser();

        const handleMessageCreated = (newMessage) => {
            setMessages((prevMessages) => {
                if (!Array.isArray(prevMessages)) {
                    return [newMessage];
                }
                return [...prevMessages, newMessage];
            });
            fetchMessages();
        };

        messageService.on('created', handleMessageCreated);
        fetchMessages();






        const notifyUserListenerAdmin = (data) => {
            console.log('admin onlye event', data)
        };

        const notifyUserListener = (data) => {
            console.log('queueupdate event', data)
        };

        const handleTelecomEvent = (data) => {
            console.log("telecom event:", data);
        };

        feathersClient.service("users").on("newUser", notifyUserListenerAdmin);
        feathersClient.service("users").on('telecom', handleTelecomEvent);
        feathersClient.service('users').on('queueupdate', notifyUserListener);


        return () => {
            messageService.off('created', handleMessageCreated);

            feathersClient.service("users").off('telecom', handleTelecomEvent);
            feathersClient.service('users').off('queueupdate', notifyUserListener);
            feathersClient.service("users").off("newUser", notifyUserListenerAdmin);
        };
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);


    const groupMessagesByDate = (messages) => {
        const groupedMessages = {};
        messages.forEach((msg) => {
            const msgDate = new Date(msg.createdAt);
            let groupKey = format(msgDate, 'dd-MM-yyyy');
            if (isToday(msgDate)) {
                groupKey = 'Aujourd\'hui';
            } else if (isYesterday(msgDate)) {
                groupKey = 'Hier';
            } else if (isSameDay(subDays(new Date(), 2), msgDate)) {
                groupKey = 'Avant-hier';
            }
            if (!groupedMessages[groupKey]) {
                groupedMessages[groupKey] = [];
            }
            groupedMessages[groupKey].push(msg);
        });
        return groupedMessages;
    };

    const renderMessages = () => {
        if (!Array.isArray(messages)) {
            return null;
        }
        const groupedMessages = groupMessagesByDate(messages);

        return Object.keys(groupedMessages).map((dateKey) => (
            <div key={dateKey}>
                <div className="text-center mt-10 relative my-2">
                    <span className="bg-[#262ea3] px-2 py-1 text-white uppercase rounded">{dateKey}</span>
                </div>
                {groupedMessages[dateKey].map((msg, index, arr) => {
                    const previousMessage = arr[index - 1];
                    const isSameSender = previousMessage && previousMessage.user_id === msg.user_id;
                    return (
                        <div key={msg.id} className={`mt-1 w-full relative mb-2 ${msg.user_id === userId ? 'text-end flex justify-end' : 'flex justify-start'} rounded`}>
                            {!isSameSender && msg.user_id !== userId && (
                                <div className='border w-7 h-7 mr-1 font-serif font-extrabold flex text-[18px] justify-center items-center rounded-full overflow-hidden'>
                                    <img className='w-full h-full object-cover' src={`https://rtc.eac-advisor.org/imageUsers/${msg.profil}`} alt='' />
                                </div>
                            )}
                            <div className={`py-1 px-2 p-4 relative ${!isSameSender && msg.user_id !== userId ? 'rounded-e-xl rounded-b-xl' : ' rounded-xl'} ${msg.user_id === userId ? 'bg-[#25cc25] text-white text-end rounded-s-xl rounded-b-xl mr-1' : 'bg-gray-100 text-start'} ${!isSameSender && msg.user_id !== userId ? null : 'ml-7'} max-w-[70%] max-sm:w-[95%]`}>
                                {!isSameSender && msg.user_id !== userId && (
                                    <small className='text-gray-400 text-[11px]'>{msg.nom_complet} ~ {msg.email}</small>
                                )}
                                <p className='text-[18px]'>{msg.content}</p>
                                <small className={`flex mt-1 p-1 items-center text-[10px] ${msg.user_id === userId ? 'justify-end text-white' : 'justify-start text-gray-400'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className={`bi mr-1 ${msg.user_id === userId ? 'text-white' : 'text-gray-400'} bi-stopwatch-fill`} viewBox="0 0 16 16">
                                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0" />
                                    </svg>
                                    {new Date(msg.createdAt).toLocaleTimeString()}
                                    <div className={`w-full h-3 -z-30 ${((!isSameSender && msg.user_id !== userId) || (!isSameSender && msg.user_id === userId)) ? msg.user_id === userId ? '-right-1  bg-[#25cc25] rounded-t-xl rounded-b-full' : '-left-1 bg-gray-100 rounded-b-full' : ''} absolute`} />
                                    <div className='ml-1'>
                                        {msg.status === 'pending' ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-clock-fill text-[blue]" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            </svg>
                                        ) : msg.status === 'error' ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill text-[red]" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-all ${msg.user_id !== userId ? 'text-[#25cc25]' : 'text-white'} `} viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z" />
                                            </svg>
                                        )}
                                    </div>
                                </small>
                            </div>
                        </div>
                    );
                })}
            </div>
        ));
    };

    return (
        <div className='overflow-hidden'>
            <div className="p-2 h-[80vh] pt-[3em] overflow-y-auto overflow-x-hidden">
                {renderMessages()}
                <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleSubmitMessage} className="p-4 border-t">
                <div className="flex items-center">
                    <textarea
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Écrivez un message..."
                        className="flex-1 p-2 bg-transparent resize-none border-none outline-none"
                    />
                    <button type="submit" className={`ml-1 p-2 bg-[#25cc25] text-white  rounded`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Discussion;
