import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { feathersClient } from '../../feathersClient/feathersClient';
import { useThemes } from '../../UserContext/UserContext';

function Registrant() {
    const { SetIsSincrire } = useThemes()
    const [profil, setProfil] = useState(null);


    const [nom_complet, setName] = useState('');
    const elemenRefname = useRef(null);

    const [tel, settel] = useState('');
    const elemenReftel = useRef(null);

    const [email, setEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const elemenRefemail = useRef(null);

    const [password, setPassword] = useState('');
    const PasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const elemenRefpassword = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!profil) {
            toast.warning('Le profil est obligatoire !!');
            elemenRefname.current && elemenRefname.current.focus();
            return;
        } else if (nom_complet.trim() === '') {
            toast.warning('Le nom est obligatoire !!');
            elemenRefname.current && elemenRefname.current.focus();
            return;
        } else if (tel.trim() === '') {
            toast.warning('Le numéro de téléphone est obligatoire !!');
            elemenReftel.current && elemenReftel.current.focus();
            return;
        } else if (email.trim() === '' || !email.match(emailRegex)) {
            toast.error("L'email est incorrect !!");
            elemenRefemail.current && elemenRefemail.current.focus();
            return;
        } else if (password.trim() === '' || !password.match(PasswordRegex)) {
            toast.error("Le mot de passe est incorrect !!");
            elemenRefpassword.current && elemenRefpassword.current.focus();
            return;
        }

        const formData = new FormData();

        if (profil) {
            formData.append('profil', profil);
        }
        formData.append('nom_complet', nom_complet);
        formData.append('email', email);
        formData.append('phone', tel);
        formData.append('status', 'users');
        formData.append('password', password);
        formData.append('IsActive', true);

        try {
            await axios.post('https://rtc.eac-advisor.org/users', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast.success("Vous inscrire avec success")
            // setProfil(null)
            // setName('')
            // settel('')
            // setEmail('')
            // setPassword('')

        } catch (error) {
            if (error.response.data.message === 'Email_existe') {
                toast.warning("Cet email est déjà existe  dans notre système");
                elemenRefemail.current && elemenRefemail.current.focus();
            }
            console.error('Erreur:', error);
        }
    }



    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            const initialUsers = await feathersClient.service("users").find();
            setUsers(initialUsers);
        } catch (error) {
            console.error("Erreur lors de la récupération des utilisateurs :", error);
        }
    };



    useEffect(() => {


      
        const handleUserCreated = (newUser) => {
            fetchUsers()
            console.log("created event", newUser)
            setUsers((prevUsers) => [...prevUsers, newUser]);
        };
       


     
        feathersClient.service("users").on("created", handleUserCreated);
       

        fetchUsers()
        return () => {
            feathersClient.service("users").off("created", handleUserCreated);
        };
    }, []);



    return (
        <>
            <div className="flex items-center justify-center overflow-x-hidden min-h-screen bg-gray-100">
                <form onSubmit={handleSubmit} className="bg-white my-4 p-6 rounded shadow-md w-full max-sm:h-full sm:w-96">
                    <h2 className="text-2xl font-bold mb-6 text-center">Inscription BTR Chat</h2>
                    <div className="w-full relative mb-2">
                        <label htmlFor="photo"
                            className={`w-full  border border-gray-300 text-black  block cursor-pointer  outline-none focus:border-orange-500  rounded-md p-2.5  bg-transparent  `}
                        >Photo de profil
                        </label>

                        <input
                            type="file"
                            name="photo"
                            accept="image/*"
                            id="photo"
                            onChange={(e) => setProfil(e.target.files[0])}
                            hidden />
                    </div>
                    {!!profil && <div className='w-[5em] mt-[1em]  relative  rounded-lg h-[5em] border border-gray-300'>
                        <img src={URL.createObjectURL(profil)} className='w-full h-full object-cover' alt='      ' />
                    </div>}

                    <div className="mb-4">
                        <label className="block text-gray-700" htmlFor="name">Nom complet</label>
                        <input
                            type="text"
                            id="name"
                            ref={elemenRefname}
                            value={nom_complet}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700" htmlFor="tel">Numéro de téléphone</label>
                        <input
                            type="text"
                            id="tel"
                            ref={elemenReftel}
                            value={tel}
                            onChange={(e) => settel(e.target.value)}
                            className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700" htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            ref={elemenRefemail}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700" htmlFor="password">Mot de Passe</label>
                        <input
                            type="password"
                            id="password"
                            ref={elemenRefpassword}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
                    </div>


                    <button type="submit" className="bg-blue-500 text-white w-full p-2 rounded hover:bg-blue-600">
                        S'inscrire
                    </button>

                    <button
                        onClick={() => SetIsSincrire(false)}
                        type="button"
                        className="text-blue-500 hover:text-blue-800 text-sm underline"
                    >
                        S'inscrire
                    </button>
                </form>


            </div></>
    )
}

export default Registrant







// import axios from 'axios';
// import React, { useEffect, useRef, useState } from 'react'
// import { toast } from 'react-toastify';
// import { feathersClient } from '../../feathersClient/feathersClient';
// import { useThemes } from '../../UserContext/UserContext';

// function Registrant() {
//     const { SetIsSincrire } = useThemes()

//     const [profil, setProfil] = useState(null);

//     const [nom_complet, setName] = useState('');
//     const elemenRefname = useRef(null);

//     const [tel, settel] = useState('');
//     const elemenReftel = useRef(null);

//     const [email, setEmail] = useState('');
//     const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
//     const elemenRefemail = useRef(null);

//     const [password, setPassword] = useState('');
//     const PasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
//     const elemenRefpassword = useRef(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!profil) {
//             toast.warning('Le profil est obligatoire !!');
//             elemenRefname.current && elemenRefname.current.focus();
//             return;
//         } else if (nom_complet.trim() === '') {
//             toast.warning('Le nom est obligatoire !!');
//             elemenRefname.current && elemenRefname.current.focus();
//             return;
//         } else if (tel.trim() === '') {
//             toast.warning('Le numéro de téléphone est obligatoire !!');
//             elemenReftel.current && elemenReftel.current.focus();
//             return;
//         } else if (email.trim() === '' || !email.match(emailRegex)) {
//             toast.error("L'email est incorrect !!");
//             elemenRefemail.current && elemenRefemail.current.focus();
//             return;
//         } else if (password.trim() === '' || !password.match(PasswordRegex)) {
//             toast.error("Le mot de passe est incorrect !!");
//             elemenRefpassword.current && elemenRefpassword.current.focus();
//             return;
//         }

//         const formData = new FormData();

//         if (profil) {
//             formData.append('profil', profil);
//         }
//         formData.append('nom_complet', nom_complet);
//         formData.append('email', email);
//         formData.append('phone', tel);
//         formData.append('status', 'users');
//         formData.append('password', password);
//         formData.append('IsActive', true);

//         try {
//             await axios.post('https://rtc.eac-advisor.org/users', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 }
//             });
//             toast.success("Vous inscrire avec success")
//             setProfil(null)
//             setName('')
//             settel('')
//             setEmail('')
//             setPassword('')

//         } catch (error) {
//             console.error('Erreur de téléchargement:', error);
//         }
//     }






//     return (
//         <>
//             <div className="flex items-center justify-center overflow-x-hidden min-h-screen bg-gray-100">
//                 <form onSubmit={handleSubmit} className="bg-white my-4 p-6 rounded shadow-md w-full max-sm:h-full sm:w-96">
//                     <h2 className="text-2xl font-bold mb-6 text-center">Inscription BTR Chat</h2>
//                     <div className="w-full relative mb-2">
//                         <label htmlFor="photo"
//                             className={`w-full  border border-gray-300 text-black  block cursor-pointer  outline-none focus:border-orange-500  rounded-md p-2.5  bg-transparent  `}
//                         >Photo de profil
//                         </label>

//                         <input
//                             type="file"
//                             name="photo"
//                             accept="image/*"
//                             id="photo"
//                             onChange={(e) => setProfil(e.target.files[0])}
//                             hidden />
//                     </div>
//                     {!!profil && <div className='w-[5em] mt-[1em]  relative  rounded-lg h-[5em] border border-gray-300'>
//                         <img src={URL.createObjectURL(profil)} className='w-full h-full object-cover' alt='      ' />
//                     </div>}

//                     <div className="mb-4">
//                         <label className="block text-gray-700" htmlFor="name">Nom complet</label>
//                         <input
//                             type="text"
//                             id="name"
//                             ref={elemenRefname}
//                             value={nom_complet}
//                             onChange={(e) => setName(e.target.value)}
//                             className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
//                     </div>

//                     <div className="mb-4">
//                         <label className="block text-gray-700" htmlFor="tel">Numéro de téléphone</label>
//                         <input
//                             type="text"
//                             id="tel"
//                             ref={elemenReftel}
//                             value={tel}
//                             onChange={(e) => settel(e.target.value)}
//                             className="w-full p-2 outline-none focus:border-blue-700 border border-gray-300 rounded" />
//                     </div>

//                     <div className="mb-4">
//                         <label className="block text-gray-700" htmlFor="email">Email</label>
//                         <input
//                             type="email"
//                             id="email"
//                             ref={elemenRefemail}
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
//                     </div>

//                     <div className="mb-4">
//                         <label className="block text-gray-700" htmlFor="password">Mot de Passe</label>
//                         <input
//                             type="password"
//                             id="password"
//                             ref={elemenRefpassword}
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded" />
//                     </div>


//                     <button type="submit" className="bg-blue-500 text-white w-full p-2 rounded hover:bg-blue-600">
//                         S'inscrire
//                     </button>
//                     <button
//                         onClick={() => SetIsSincrire(false)}
//                         type="button"
//                         className="text-blue-500 hover:text-blue-800 text-sm underline"
//                     >
//                         Se connecte
//                     </button>
//                 </form>


//             </div></>
//     )
// }

// export default Registrant