/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './App.css'
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "apexcharts/dist/apexcharts.css";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'rsuite/dist/rsuite.min.css';
import 'aos/dist/aos.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";
import "react-chat-elements/dist/main.css"


import { Routes, Route, useNavigate, } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { CustomProvider } from "rsuite";
import Registrant from "./Component/formilaire/Registrant";
import Login from "./Component/formilaire/Login";
import RegistrantAdmin from "./Component/formilaire/RegistrantAdmin";
import { useEffect } from 'react';
import { useThemes } from './UserContext/UserContext';
import ComponentDiscusion from './Component/ComponentDiscusion/ComponentDiscusion';


function App() {

    const { isSincrire, isAdmin, SetIsAdmin, isUsers, SetIsUsers, isVisitor, SetIsVisitor } = useThemes()
    const role = localStorage.getItem('status');
    const navig = useNavigate()

    useEffect(() => {
        if (role === "admin") {
            SetIsAdmin(true);
            SetIsUsers(false)
            SetIsVisitor(false)
            navig("/chat/admin")
        } else if (role === 'users') {
            SetIsAdmin(false);
            navig("/chat/users")
            SetIsUsers(true)
            SetIsVisitor(false)
        } else {
            SetIsVisitor(true)
            navig("/")
        }
    }, [role, isAdmin, isUsers, isVisitor]);


    return isVisitor ? (
        <>
            {!isSincrire && <Login />}
            {isSincrire && <Registrant />}
            <ToastContainer />
        </>

    ) : isUsers ? (
        <CustomProvider>
            <Routes>
                <Route path="/chat/users" Component={ComponentDiscusion} />
            </Routes>
            <ToastContainer />
        </CustomProvider>

    ) : isAdmin ? (
        <CustomProvider>
            <Routes>
                <Route path="/chat/admin" Component={ComponentDiscusion} />
            </Routes>
            <ToastContainer />
        </CustomProvider>
    ) : null

}

export default App




//  ......................chat header........................

// import { Phone, Video, Search, MoreVertical } from 'lucide-react';
// import { Button } from '@/components/ui/button';
// import { Avatar } from '@/components/ui/avatar';

// interface ChatHeaderProps {
//   chatId?: string;
// }

// export default function ChatHeader({ chatId }: ChatHeaderProps) {
//   return (
//     <div className="flex items-center justify-between px-6 py-3 border-b bg-card">
//       <div className="flex items-center space-x-4">
//         <Avatar className="h-10 w-10">
//           <img
//             src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400&auto=format&fit=crop&q=60"
//             alt="User avatar"
//             className="rounded-full"
//           />
//         </Avatar>
//         <div>
//           <h3 className="text-lg font-semibold">Alice Cooper</h3>
//           <p className="text-sm text-muted-foreground">Online</p>
//         </div>
//       </div>
//       <div className="flex items-center space-x-2">
//         <Button variant="ghost" size="icon">
//           <Phone className="h-5 w-5" />
//         </Button>
//         <Button variant="ghost" size="icon">
//           <Video className="h-5 w-5" />
//         </Button>
//         <Button variant="ghost" size="icon">
//           <Search className="h-5 w-5" />
//         </Button>
//         <Button variant="ghost" size="icon">
//           <MoreVertical className="h-5 w-5" />
//         </Button>
//       </div>
//     </div>
//   );
// }


// ..................................chat input.................
// import { useState, KeyboardEvent } from 'react';
// import { Paperclip, Smile, Send, Mic } from 'lucide-react';
// import { Button } from '@/components/ui/button';
// import { Input } from '@/components/ui/input';
// import { Separator } from '@/components/ui/separator';

// interface ChatInputProps {
//   onSend: (message: string) => void;
// }

// export default function ChatInput({ onSend }: ChatInputProps) {
//   const [message, setMessage] = useState('');

//   const handleSend = () => {
//     if (message.trim()) {
//       onSend(message.trim());
//       setMessage('');
//     }
//   };

//   const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSend();
//     }
//   };

//   return (
//     <div className="p-4 bg-card border-t">
//       <div className="flex items-center space-x-2">
//         <Button variant="ghost" size="icon">
//           <Paperclip className="h-5 w-5" />
//         </Button>
//         <Input
//           type="text"
//           placeholder="Type a message..."
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className="flex-1"
//         />
//         <Button variant="ghost" size="icon">
//           <Smile className="h-5 w-5" />
//         </Button>
//         <Button variant="ghost" size="icon">
//           <Mic className="h-5 w-5" />
//         </Button>
//         <Button onClick={handleSend} disabled={!message.trim()}>
//           <Send className="h-5 w-5" />
//         </Button>
//       </div>
//     </div>
//   );
// }


// .............................view chart ........................

// import { useState, useRef, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import ChatHeader from './ChatHeader';
// import MessageList from './MessageList';
// import ChatInput from './ChatInput';
// import { Message } from '@/types/chat';

// export default function ChatView() {
//   const { id } = useParams();
//   const [messages, setMessages] = useState<Message[]>([]);
//   const [isTyping, setIsTyping] = useState(false);
//   const scrollRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     // Simulated initial messages load
//     setMessages([
//       {
//         id: '1',
//         content: 'Hey! How are you?',
//         sender: 'Alice',
//         timestamp: new Date(Date.now() - 3600000),
//         isOwn: false,
//         status: 'read',
//       },
//       {
//         id: '2',
//         content: "I'm doing great! Just finished working on that new project.",
//         sender: 'You',
//         timestamp: new Date(Date.now() - 3000000),
//         isOwn: true,
//         status: 'delivered',
//       },
//     ]);
//   }, [id]);

//   const handleSend = (content: string) => {
//     const newMessage: Message = {
//       id: Date.now().toString(),
//       content,
//       sender: 'You',
//       timestamp: new Date(),
//       isOwn: true,
//       status: 'sent',
//     };
//     setMessages([...messages, newMessage]);
//   };

//   return (
//     <div className="flex flex-col h-full">
//       <ChatHeader chatId={id} />
//       <MessageList
//         messages={messages}
//         isTyping={isTyping}
//         scrollRef={scrollRef}
//       />
//       <ChatInput onSend={handleSend} />
//     </div>
//   );
// }

//.............................Messaget items

// import { Check, CheckCheck } from 'lucide-react';
// import { Message } from '@/types/chat';
// import { cn } from '@/lib/utils';

// interface MessageItemProps {
//   message: Message;
// }

// export default function MessageItem({ message }: MessageItemProps) {
//   return (
//     <div
//       className={cn('flex', message.isOwn ? 'justify-end' : 'justify-start')}
//     >
//       <div
//         className={cn(
//           'max-w-[70%] p-3 shadow-sm',
//           message.isOwn
//             ? 'bg-primary text-primary-foreground rounded-l-lg rounded-tr-lg'
//             : 'bg-accent text-accent-foreground rounded-r-lg rounded-tl-lg'
//         )}
//       >
//         <p className="text-sm">{message.content}</p>
//         <div className="flex items-center justify-end gap-1 mt-1">
//           <span className="text-xs opacity-70">
//             {message.timestamp.toLocaleTimeString([], {
//               hour: '2-digit',
//               minute: '2-digit',
//             })}
//           </span>
//           {message.isOwn && (
//             <span className="text-xs">
//               {message.status === 'sent' && <Check className="h-3 w-3" />}
//               {message.status === 'delivered' && (
//                 <CheckCheck className="h-3 w-3" />
//               )}
//               {message.status === 'read' && (
//                 <CheckCheck className="h-3 w-3 text-blue-400" />
//               )}
//             </span>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }




// ...................message list
// import { RefObject } from 'react';
// import { Message } from '@/types/chat';
// import MessageItem from './MessageItem';
// import { ScrollArea } from '@/components/ui/scroll-area';

// interface MessageListProps {
//   messages: Message[];
//   isTyping: boolean;
//   scrollRef: RefObject<HTMLDivElement>;
// }

// export default function MessageList({ messages, isTyping, scrollRef }: MessageListProps) {
//   return (
//     <ScrollArea className="flex-1 p-4" ref={scrollRef}>
//       <div className="space-y-4">
//         {messages.map((message) => (
//           <MessageItem key={message.id} message={message} />
//         ))}
//         {isTyping && (
//           <div className="flex items-center space-x-2 text-muted-foreground">
//             <div className="typing-indicator">
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//             <span className="text-sm">Alice is typing...</span>
//           </div>
//         )}
//       </div>
//     </ScrollArea>
//   );
// }