/* eslint-disable jsx-a11y/anchor-is-valid */
// LoginPage.js
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useThemes } from '../../UserContext/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { SetIsSincrire, SetIsAdmin, SetIsVisitor, SetIsUsers } = useThemes()
    const navig = useNavigate()
    const [isLoading, SetIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const elemenRefemail = useRef(null);

    const [password, setPassword] = useState('');
    const PasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const elemenRefpassword = useRef(null);

    const [showEye, SetshowEye] = useState(false);

    const handleSubmitLogine = async (e) => {
        e.preventDefault();
        if (email.trim() === '') {
            toast.warning('L\'email est obligatoire !!');
            elemenRefemail.current && elemenRefemail.current.focus();
            return false;
        } else if (!email.match(emailRegex)) {
            toast.error('L\'email est incorrect !!');
            elemenRefemail.current && elemenRefemail.current.focus();
            return false;
        } else if (password.trim() === '') {
            toast.warning('Le mot de passe est obligatoire !!');
            elemenRefpassword.current && elemenRefpassword.current.focus();
            return false;
        } else if (!password.match(PasswordRegex)) {
            toast.error('Le mot de passe est incorrect !!');
            elemenRefpassword.current && elemenRefpassword.current.focus();
            return false;
        } else {
            try {
                SetIsLoading(true);

                const response = await axios.post('https://rtc.eac-advisor.org/authentication', {
                    strategy: 'local',
                    email,
                    password
                });

                const dataUsers = response && response.data
                console.log("response", dataUsers)
                if (dataUsers && dataUsers.accessToken) {
                    localStorage.setItem('userId', dataUsers.user.id);
                    localStorage.setItem('accessToken_Data', dataUsers.accessToken);
                    localStorage.setItem('status', dataUsers.user.status);
                    if (dataUsers.user.status === 'admin') {
                        SetIsAdmin(true)
                        SetIsUsers(false)
                        toast.success("Authentification réussie !");
                        navig("/chat/admin")
                        SetIsVisitor(false)
                    } else if (dataUsers.user.status === 'users') {
                        SetIsUsers(true)
                        SetIsAdmin(false)
                        SetIsVisitor(false)
                        toast.success("Authentification réussie !");
                        navig("/chat/users")
                    } else {
                        SetIsVisitor(true)
                    }
                    SetIsLoading(false);
                    return response;
                } else {
                    SetIsLoading(false);
                    toast.error('Le token d\'accès n\'a pas été fourni.');
                }
            } catch (error) {
                SetIsLoading(false);
                if (error.response.data.message === 'email_not_find') {
                    toast.warning("Cet email n'existe pas dans notre système");
                    elemenRefemail.current && elemenRefemail.current.focus();
                } else if (error.response.data.message === 'pass_word_invalide') {
                    toast.warning("Mot de passe incorrect, Veuillez réessayer.");
                    elemenRefpassword.current && elemenRefpassword.current.focus();
                } else {
                    toast.error("Erreur d'authentification. Veuillez réessayer.");
                }
                console.error("Erreur d'authentification:", error.response);
                SetIsLoading(false);

            }
        }
    };

    return (
        <div className="flex items-center justify-center overflow-x-hidden min-h-screen bg-gray-100">
            <form onSubmit={handleSubmitLogine} className="bg-white p-6 rounded shadow-md w-full max-sm:h-full sm:w-96">
                <h2 className="text-2xl font-bold mb-6 text-center font-serif uppercase text-[blue]">Se connecter BTR Chat</h2>
                <div className="mb-4">
                    <label className="block text-gray-700" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        ref={elemenRefemail}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border outline-none focus:border-blue-700 border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4 relative">
                    <label className="block text-gray-700" htmlFor="password">Mot de Passe</label>
                    <div className='flex relative'>
                        <input
                            ref={elemenRefpassword}
                            onChange={(e) => setPassword(e.target.value)}
                            type={`${showEye ? 'text' : 'password'}`}
                            className={`w-full border pr-8 outline-none focus:border-blue-700 border-gray-300 rounded-md p-2.5 bg-transparent`}
                            placeholder="Xxxxx@12"
                        />
                        <div onClick={() => SetshowEye(!showEye)} className={`absolute cursor-pointer w-7 right-1 h-[97%] flex justify-center items-center`}>
                            {showEye ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-eye rounded hover:bg-gray-200 transition-all p-2" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-eye-slash rounded hover:bg-gray-200 transition-all p-2" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.707 12 12-.708.707z" />
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
                <div className=" justify-between hidden mb-4">
                    <div>
                        <a href="#" className="text-sm text-blue-500 hover:text-blue-800">Mot de passe oublié ?</a>
                    </div>
                </div>
                <div className="flex justify-between">
                    <button
                        type="submit"

                        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isLoading ? 'Chargement...' : 'Se connecter'}
                    </button>
                    <button
                        onClick={() => SetIsSincrire(true)}
                        type="button"
                        className="text-blue-500 hover:text-blue-800 text-sm underline"
                    >
                        S'inscrire
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Login;
